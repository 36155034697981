<template>
  <div
    :class="{
      'value-input-text mr-md-1': true,
      'w-100 px-3 mb-1': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-text-field
      :value="value"
      clearable
      dense
      placeholder="Input a text"
      outlined
      height="30px"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "ValueInputText",
  props: {
    value: {
      default: () => "",
    },
  },
};
</script>

<style lang="scss" scoped>
.value-input-text {
  width: 130px;
  input {
    text-align: center;
  }
  .v-text-field__details {
    display: none;
  }
}

.search-filters-container {
  input[type="text"], input[type="number"] {
    text-align: left;
  }
}

.value-input-text {
  .v-text-field {
    ::v-deep .v-input__slot {
      min-height: 30px !important;
      border-radius: 4px;
      border: solid 1px #dee7ee !important;
      background-color: #ecf1f5 !important;
      margin-bottom: 0;
      padding: 0 0 0 8px !important;
      fieldset {
        border-width: 0;
      }
      input {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #66788e;
      }
    }
    ::v-deep input {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    ::v-deep .v-input__append-inner {
      margin-top: 3px !important;
      button {
        font-size: 16px;
      }
    }

    ::v-deep .v-text-field__details {
      display: none;
    }
  }
}
</style>
